var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{ref:"inputTable",staticClass:"table-responsive table-flush position-table table-tablet",staticStyle:{"width":"100%","overflow":"auto"},attrs:{"header-row-class-name":"thead-light","data":_vm.tabletItems,"row-key":"id","row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"type":"expand","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('el-row',[_c('el-col',[_c('table',[_c('tr',[_c('th',{staticClass:"expanded-header"},[_vm._v(_vm._s(_vm.$t('message.width')))]),_c('th',{staticClass:"expanded-header"},[_vm._v(_vm._s(_vm.$t('message.height')))]),_c('th',{staticClass:"expanded-header"},[_vm._v(_vm._s(_vm.$t('data_input.material_manager.perimeter_placeholder')))]),_c('th',{staticClass:"expanded-header"},[_vm._v(_vm._s(_vm.$t('message.unit')))]),_c('th',{staticClass:"expanded-header"}),_c('th',{staticClass:"expanded-header"},[_vm._v(_vm._s(_vm.$t('message.id')))]),_c('th',{staticClass:"expanded-header"},[_vm._v(_vm._s(_vm.$t('message.additionalInformation')))]),_c('th',{staticClass:"expanded-header"},[_vm._v(_vm._s(_vm.$t('message.result')))])]),_c('tr',[_c('td',{staticClass:"expanded-row"},[_vm._v(_vm._s(props.row.width))]),_c('td',{staticClass:"expanded-row"},[_vm._v(_vm._s(props.row.height))]),_c('td',{staticClass:"expanded-row"},[(props.row.extent)?_c('span',[_vm._v(_vm._s(_vm._f("number")(props.row.extent)))]):_vm._e()]),_c('td',{staticClass:"expanded-row"},[_vm._v(_vm._s(props.row.unit))]),_c('td',[(!props.row.additional)?_c('el-tooltip',{attrs:{"content":_vm.$t('position.go-to-bhb'),"placement":"top"}},[_c('base-button',{attrs:{"type":"link","size":"sm"},on:{"click":function($event){return _vm.$router.push({
                      path: ("/project/" + (_vm.$route.params.id) + "/bhb/" + (props.row.position_id)),
                    })}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-edit text-black"})])])],1):_vm._e()],1),_c('td',{staticClass:"expanded-row"},[_vm._v(_vm._s(props.row.pos.key))]),_c('td',{staticClass:"expanded-row left"},[_vm._v(_vm._s(props.row.pos.cl_info))]),_c('td',{staticClass:"expanded-row"},[_vm._v(_vm._s(props.row.rueck_result))])])])])],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.backNote'),"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('el-row',[_c('el-col',{attrs:{"span":2}},[_c('el-checkbox',{attrs:{"value":row.state_rueck == 'ZURUECK',"disabled":!_vm.currentUserRights.includes(
                'Rückeinlagerungsliste_Abhaken'
              ) || _vm.project.state === 'ARCHIVED'},on:{"change":function (value) { return _vm.changeState(row, value); }}})],1),_c('el-col',{staticClass:"ml-2",attrs:{"span":14}},[_c('el-select',{attrs:{"placeholder":"Resultat","disabled":!_vm.currentUserRights.includes(
                'Rückeinlagerungsliste_Bemerkungen verfassen'
              ) || _vm.project.state === 'ARCHIVED'},on:{"change":function($event){return _vm.changeResult(row)}},model:{value:(row.rueck_result),callback:function ($$v) {_vm.$set(row, "rueck_result", $$v)},expression:"row.rueck_result"}},_vm._l((_vm.tabletResults),function(option){return _c('el-option',{key:option,attrs:{"label":option,"value":option}})}),1)],1),_c('el-col',{attrs:{"span":2}},[_c('base-button',{attrs:{"type":"link","size":"md","disabled":_vm.project.state === 'ARCHIVED'},on:{"click":function($event){return _vm.showComments(row)}}},[_c('span',{staticClass:"btn-inner--icon ml-2"},[(row.rueck_bemerkung)?_c('i',{staticClass:"fas fa-comment"}):_c('i',{staticClass:"far fa-comment text-black"})])])],1)],1),(row.user_rueck_ref)?_c('el-row',[_c('el-col',{attrs:{"span":19}},[_vm._v(" "+_vm._s(row.user_rueck_ref.name)+", "+_vm._s(_vm._f("datetime")(row.date_rueck))+" ")])],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":"PT-NR.","width":"100","prop":"pos.pt_number"}}),_c('el-table-column',{attrs:{"prop":"category.name","label":_vm.$t('global.category'),"width":"150","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("category")(row,_vm.currentLocale))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.materialConstruction'),"width":"220","sortable":"","sort-method":_vm.sortMaterial},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [(row.article && !row.additional)?_c('div',[_vm._v(" "+_vm._s(_vm._f("material")(row,_vm.currentLocale))+" ")]):_vm._e(),(row.additional)?_c('div',[_vm._v(" "+_vm._s(row.article)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.amount'),"align":"right","header-align":"right","width":"100","prop":"quantity","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("number")(row.quantity))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.result_customer'),"width":"160","prop":"rueck_result_customer","sortable":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }